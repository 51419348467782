import axios, { AxiosRequestHeaders } from "axios"

const Request = (auth = false) => {
    const headers: AxiosRequestHeaders = {};

    if (auth) {
        headers.Authorization = `Bearer ${sessionStorage.getItem('user-token')}`;
    }

    let ax = axios.create({
        headers: headers,
        baseURL: 'https://api.shortlinks.hosted.cool/api'
    });

    return ax;
}

export default Request;
